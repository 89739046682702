<template>
  <section id="dashboard">

    <div class="container">
      <!-- <h1 class="mb-4">Dashboard</h1> -->

        <div class="row">
            <SideNav v-bind:selected="3"/>

            <!-- SECOND HALF -->
            <div class="col-xs-12 col-sm-12 col-md-12 mt-md-0">
                <form id="payment_method" class="mt-2">
                  <label class="mb-2 small"><option value="method">Select Payment Method</option></label>
                  <select v-model="method" name="method_type" id="method_type">
                    <option name="stripe" value="0">Visa | Mastercard</option>
                    <!-- <option name="paypal" value="1">PayPal</option> -->
                  </select>
                  <div class="mb-2"></div>
                  <label class="mb-2 small"><option value="method">Enter amount</option></label>

                  <input id="ticket_price" type="number" name="ticket_price" placeholder="£" min="1" max="1000" v-model="amount"/>

                  <div class="total mt-3">

                    <label class="mt-2 mb-2 total_title">Total</label>

                    <div class="total_price">
                        <div class="text-end">
                          <svg xmlns="http://www.w3.org/2000/svg" width="143" height="33" viewBox="0 0 105 44">
                            <circle id="Ellipse_225" data-name="Ellipse 225" cx="22" cy="22" r="22" fill="#00bca4"/>
                            <rect id="Rectangle_169" data-name="Rectangle 169" width="143" height="44" rx="22" fill="rgba(0,188,164,0.27)"/>
                            <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M23.758,18.825v5.244a.44.44,0,0,1-.457.457H9.64a.44.44,0,0,1-.457-.457V21.925a.464.464,0,0,1,.457-.457h1.386V16H9.669a.433.433,0,0,1-.329-.136.452.452,0,0,1-.129-.322V13.666a.44.44,0,0,1,.457-.457h1.357V10.023a5.18,5.18,0,0,1,1.765-4.03,6.468,6.468,0,0,1,4.494-1.586,7.291,7.291,0,0,1,4.787,1.786.422.422,0,0,1,.143.293.442.442,0,0,1-.1.322L20.643,8.622a.438.438,0,0,1-.314.171.383.383,0,0,1-.329-.1,3.14,3.14,0,0,0-.372-.271,5.2,5.2,0,0,0-.986-.457,3.775,3.775,0,0,0-1.329-.257,2.808,2.808,0,0,0-1.958.672,2.257,2.257,0,0,0-.743,1.758v3.072h4.358a.452.452,0,0,1,.321.129.433.433,0,0,1,.136.329v1.872a.464.464,0,0,1-.457.457H14.613v5.416h5.916V18.825a.452.452,0,0,1,.129-.321.433.433,0,0,1,.329-.136H23.3a.433.433,0,0,1,.329.136.452.452,0,0,1,.129.321Z" transform="translate(5.957 6.593)" fill="#fff"/>
                            <text id="_10.50" data-name="10.50" transform="translate(90 33)" fill="#fff" font-size="30" font-family="NirmalaUI, Nirmala UI"><tspan x="-35.596" y="0">{{amount ? parseFloat(amount).toFixed(2) : '0.00'}}</tspan></text>
                          </svg>                          
                        </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col">
                      </div>
                      <div class="col">
                        <button type="button" class="btn btn-custom mt-3" v-on:click="purchase">Pay</button> 
                      </div>
                    </div>



                  </div>

                </form>
            </div>

            <!-- Hidden form -->
            <form method="POST" action="https://payments.securetrading.net/process/payments/choice">
              <input type="hidden" name="sitereference" value="competitionmanialtd88108">
              <input type="hidden" name="stprofile" value="default">
              <input type="hidden" name="currencyiso3a" value="GBP">
              <input id="securetrading-ref" type="hidden" name="orderreference">
              <input id="securetrading-sum" type="hidden" name="mainamount" value="100.00">
              <input type="hidden" name="version" value="2">
 
              <input style="display: none;" id="securetrading-form" type="submit" value="Pay">
              
              <input type=hidden name="ruleidentifier" value="STR-7">
              <input type=hidden name="declinedurlredirect" value="https://competitionmania.co.uk/dashboard/topup">

              <input type=hidden name="ruleidentifier" value="STR-13">
              <input type=hidden name="errorurlredirect" value="https://competitionmania.co.uk/dashboard/topup">
 
              <input type=hidden name="ruleidentifier" value="STR-6">
              <input type=hidden name="successfulurlredirect" value="https://competitionmania.co.uk/api/billing/card/callback">
            </form>
        </div>
    </div>
  </section>
  <Footer />
</template>

<script>

import Api from "../../scripts/api"

import Footer from "../../components/global/Footer"
import SideNav from '../../components/dashboard/SideNavigator'

//import {loadStripe} from '@stripe/stripe-js';

export default {
  components: {
    Footer,
    SideNav
  },
  props: {
    account: Object
  },
  data() {
    return {
      amount: undefined,
      method: "0"
    }
  },
  methods: {
    purchase: async function() {

      if(!this.amount || parseFloat(this.amount) < 0) return this.$toast.warning('Please enter an amount first.')
      if(parseFloat(this.amount) < 5)
          return this.$toast.warning('Top up amount must be at least £5.'); 

      if(this.method == '0') {
        if(parseFloat(this.amount) < 5)
          return this.$toast.warning('Top up amount must be at least £5.'); 
        document.getElementById('securetrading-sum').value = parseFloat(this.amount).toFixed(2);
        document.getElementById('securetrading-ref').value = this.account.account.id;
        return document.getElementById('securetrading-form').click();
      }

      var result = await Api.purchase(this.amount, this.method);
      if (result.status == 422) return this.$toast.warning('Top up amount must be at least £5.'); 
      if (result) {
        var data = await result.json();
        if(data.method == 0) {
          //const stripe = await loadStripe('pk_live_51IQug3DaW5lU8k6E0tcNkpTYfdx3WzD7kCtxa5T4knjiskyZMpvBuyxvdpKmNQAmaRr64svXDYbPoZswEAN0VoNu00o171Np5U');
          //return stripe.redirectToCheckout({sessionId: data.redirect});
        } 
        if(data.method == 1) {
          window.location.href = data.redirect;
        }
      }
    }
  }
};
</script>

